const giftData = {
  number_of_months: 3,
  has_gift_message: true,
  recipient_name: "TEST RECIPIENT",
  sender_name: "TEST SENDER",
  gift_card_message: "TEST \nGIFT CARD \nMESSAGE",
  gift_card_lines: [],
};

const shippingData = {
  shipping_addressee: "TEST",
  shipping_address_1: "34 W 27th street",
  shipping_city: "New York",
  shipping_state: "NY",
  shipping_zip: "10001",
  tawSort: "TST",
};

const barcodeData = {
  externalId: "30000000",
  printQueue: "giftCard",
  barcode: "",
  visible_barcode: "420284039241990172378415426097",
};

const bookClubsData = {
  sales_order_id_suffix: "10000",
  book_club_name: "TEST CLUB",
  book_club_type: "GROUP OR INDIVIDUAL",
  short_transaction_data: "2020-07-20",
  items: [{ short_title: "Short Title 1", quantity: 2, id: "AA1001" }],
  display_names: [{ display_name: "Name1" }, { display_name: "Name2" }],
};

const orderData = {
  flow: [
    { id: "AA1001", title: "Book one" },
    { id: "AA2002", title: "Book two" },
  ],
};

export default {
  data: {
    ...barcodeData,
    ...shippingData,
    ...orderData,
    ...bookClubsData,
    ...giftData,
  },
};
