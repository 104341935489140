import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactCodeMirror from "@uiw/react-codemirror";
import handlebars from "handlebars";
import bwipjs from "bwip-js";
import { Header } from "../../../../components/common/Header";
import initData from "./initialData";

const renderBarcode = (c, opts) =>
  new Promise((resolve, reject) => {
    bwipjs(c, opts, (err) => (err ? reject(err) : resolve(null)));
  });

const getVisibleBarcode = (visibleBarcode) => {
  return (
    "(" +
    visibleBarcode.slice(0, 3) +
    ")" +
    visibleBarcode.slice(3, 8) +
    "(" +
    visibleBarcode.slice(8, 10) +
    ")" +
    visibleBarcode.slice(10)
  );
};

interface TemplateData {
  id?: number;
  name?: string;
  template?: string;
  renderWidth?: string;
  renderHeight?: string;
}

declare global {
  interface Window {
    globalSetData: any;
  }
}

window.globalSetData = window.globalSetData || {};

const defaultData = {
  id: 0,
  name: "",
  template: "",
  renderWidth: "4",
  renderHeight: "5",
};

const InvoiceTemplate = () => {
  const { id } = useParams<{ id }>();

  const [templateData, setTemplateData] = useState<TemplateData>(defaultData);
  const [preview, setPreview] = useState(false);
  const [editData, setEditData] = useState(false);
  const [data, setData] = useState(initData);

  const getTemplate = async (id) => {
    let res = await fetch(`/api/template/${id}`, {
      method: "GET",
      credentials: "include",
    });
    const {
      id: templateId,
      name,
      template,
      renderWidth,
      renderHeight,
    } = await res.json();
    setTemplateData({
      id: templateId,
      name,
      template,
      renderWidth,
      renderHeight,
    });
  };

  const updateData = async () => {
    // const visibleBarcode = getVisibleBarcode(data.data.visible_barcode);
    const visibleBarcode = data.data.externalId + "0";
    const renderableCode = visibleBarcode;
    const canvas = document.createElement("canvas");
    const opts = {
      bcid: "code128", // "gs1-128" for this format: '(420)80026(92)61290172378435251384'
      text: renderableCode,
    };
    await renderBarcode(canvas, opts);
    const barcode = canvas.toDataURL();
    const gift_card_lines = data.data.gift_card_message.split("\n");

    const updatedData = { ...data.data, barcode, gift_card_lines };
    setData({ ...data, data: updatedData });
  };

  useEffect(() => {
    getTemplate(id);
  }, []);
  useEffect(() => {
    updateData();
  }, [data.data.visible_barcode]);

  const setIframe = (iframe, templateString) => {
    if (iframe) {
      const content =
        iframe.contentWindow ||
        iframe.contentDocument.document ||
        iframe.contentDocument;

      const handlebarsTemplate = handlebars.compile(templateString);

      content.document.open();
      content.document.write(handlebarsTemplate(data));
      content.document.close();
    }
  };

  const saveChanges = async () => {
    if (confirm("Are you sure you want to save your changes?")) {
      await fetch(`/api/template/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(templateData),
      });
      await getTemplate(id);
    }
  };

  const globalSetData = async (input) => {
    for (let key in input) {
      if (input[key] && input[key].barcode) {
        const canvas = document.createElement("canvas");
        await renderBarcode(canvas, input[key].opts);
        input[key] = canvas.toDataURL();
      }
    }
    input = { ...data.data, ...input };
    setData({ data: input });
  };

  window.globalSetData = globalSetData;

  let main;
  if (preview) {
    main = (
      <iframe
        style={{
          minHeight: templateData.renderHeight,
          minWidth: templateData.renderWidth,
        }}
        ref={(iframe) => {
          setIframe(iframe, templateData.template);
        }}
      />
    );
  } else {
    main = (
      <React.Fragment>
        <button onClick={() => setEditData(!editData)}>Edit Data</button>
        {editData && (
          <ReactCodeMirror
            value={JSON.stringify(data, null, 2)}
            basicSetup={{
              lineNumbers: true,
            }}
            onChange={(value) => {
              setData(JSON.parse(value));
            }}
          />
        )}
        <ReactCodeMirror
          value={templateData.template}
          basicSetup={{
            lineNumbers: true,
          }}
          onChange={(value) => {
            setTemplateData({ ...templateData, template: value });
          }}
        />
      </React.Fragment>
    );
  }

  return (
    <div>
      <Header url="/management/invoice-templates">
        Back to Invoice Templates
      </Header>
      <button onClick={saveChanges} style={{ backgroundColor: "red" }}>
        Save Changes
      </button>
      <button onClick={() => setPreview(!preview)}>
        {preview ? "Edit" : "Preview"}
      </button>
      <br />
      {main}
    </div>
  );
};

export default InvoiceTemplate;
